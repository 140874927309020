import React, { useState } from "react";
//import { useWeb3React } from "@web3-react/core";
import { NavLink } from "react-router-dom";

import "./Header.scss";
import logo from "assets/images/logo.png";
//import UnlockWallet from "components/UnlockWallet";
//import { getSlicedValue } from "helpers/utilities";
//import Button from "components/Button";
//import { ReactComponent as Menu } from "assets/icons/menu.svg";
import Sidebar from "./Sidebar";

const Header = () => {
 // const { account } = useWeb3React();
  const [sidebar, setSidebar] = useState(false);

  const navlinks = (
    <ul className="nav_links">
      <li>
        <NavLink to="/">Home</NavLink>
      </li> 
   {/*   <li>
        <a href="https://tokenomics.platypuus.xyz/" target="_blank" rel="noopener noreferrer"><strong>Tokenomics</strong></a>
      </li>
      <li>
         <a href="https://platypuus.medium.com/platypuustoken-airdrop-ppt-464507e541d2" target="_blank" rel="noopener noreferrer"><strong>Airdrop</strong></a>
      </li>
       <li>
        <a href="https://dexscreener.com/arbitrum/0x6ae02a897e2ca594eeac960612ba87598bf69064" target="_blank" rel="noopener noreferrer"><strong>Dexscreener</strong></a>
      </li>
       <li>
        <a href="https://www.geckoterminal.com/arbitrum/pools/0x6ae02a897e2ca594eeac960612ba87598bf69064" target="_blank" rel="noopener noreferrer"><strong>Geckoterminal</strong></a>
      </li>
      <li>
        <a href="https://app.uniswap.org/swap?outputCurrency=0xDBc89fcbD318dF6d111Efe9DdBd96C9f4652f60a&chain=arbitrum" target="_blank" rel="noopener noreferrer"><strong>Buy $PPT</strong></a>
      </li>  
       <li>
        <a href="https://notion.com" target="_blank" rel="noopener noreferrer"><b>FAQ's</b></a>
      </li>   */}
    </ul>
  );


  return (
    <>
      <header>
        <div className="mx pad header">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          {/* {navlinks}
         <div className="nav_controls">
            {account ? <Button>{getSlicedValue(account)}</Button> : <UnlockWallet />}
            <div className="menu" onClick={() => setSidebar(true)}> 
              <Menu />
            </div>
          </div> */}
        </div>
      </header>
      <Sidebar sidebar={sidebar} handleClose={() => setSidebar(false)}>
        {navlinks}
      </Sidebar>
    </>
  );  
};


  export default Header;
