import React from "react";
import { ReactComponent as Discord } from "../../assets/icons/discord.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";

// Commented out the following imports and components as they are not being used in this snippet:
// import vvsfinance from "../../assets/partners/vvsfinance.png";
// import cryptocom from "../../assets/partners/cryptocom.png";
// import debank from "../../assets/partners/debank.png";
// import defiwallet from "../../assets/partners/defiwallet.png";
// import mmf from "../../assets/partners/mmf.png";
// import defilama from "../../assets/partners/defilama.png";
// import cronos from "../../assets/partners/cronos.png";
// import assure from "../../assets/partners/assure.png";
// import dappradder from "../../assets/partners/dappradder.png";
// import { ReactComponent as Zealy } from "../../assets/icons/zealy.svg";

/*
const images = [
  { image: cryptocom, alt: "cryptocom", id: Math.random().toString(16) },
  { image: debank, alt: "debank", id: Math.random().toString(16) },
  { image: defiwallet, alt: "defiwallet", id: Math.random().toString(16) },
  { image: vvsfinance, alt: "vvsfinance", id: Math.random().toString(16) },
  { image: cronos, alt: "cronos", id: Math.random().toString(16) },
  { image: mmf, alt: "mmf", id: Math.random().toString(16) },
  { image: assure, alt: "assure", id: Math.random().toString(16) },
  { image: dappradder, alt: "dappradder", id: Math.random().toString(16) },
  { image: defilama, alt: "defilama", id: Math.random().toString(16) },
];

const Banner = ({ images, speed = 10000 }) => {
  const styles = { "--speed": `${speed}ms` } as React.CSSProperties;
  return (
    <div className="inner">
      <div className="wrapper">
        <section style={styles}>
          {images.map(({ id, image, alt }) => (
            <div className="image" key={id}>
              <img src={image} alt={alt} />
            </div>
          ))}
        </section>
        <section style={styles}>
          {images.map(({ id, image, alt }) => (
            <div className="image" key={id}>
              <img src={image} alt={alt} />
            </div>
          ))}
        </section>
        <section style={styles}>
          {images.map(({ id, image, alt }) => (
            <div className="image" key={id}>
              <img src={image} alt={alt} />
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export { Banner };
*/

const Footer = () => {
  return (
    <div className="footer">
      {/*
      <div className="partners">
        <p style={{ color: "#fff", fontWeight: "600" }} className="mb-30">
          Ecosystem Partners
        </p>
        <div className="banners">
          <Banner images={images} />
        </div>
      </div>
      */}
      <div className="icon">
        <p style={{ fontWeight: "600" }}>Follow us on</p>
        <div className="icons">
          <a href="https://x.com/platypuus_xyz" target="_blank" rel="noopener noreferrer">
            <Twitter />
          </a>
          <a href="https://discord.gg/MrEfum2d" target="_blank" rel="noopener noreferrer">
            <Discord />
          </a>
        </div>
      </div>
      <div className="copyright">
        <h4>Platypuus Project</h4>
        <p>©Platypuus.xyz, 2024</p>
      </div>
    </div>
  );
};

export default Footer;
