import React from "react";

//import { ReactComponent as Illustration1 } from "assets/images/logo1.svg";
import { ReactComponent as Illustration2 } from "assets/images/logo2.svg";
//import { ReactComponent as Illustration3 } from "assets/images/logo3.svg";

const Hero = () => {
  return (
    <div className="hero pad">
      <div className="mx">
        <div className="hero_content">
          <h2>Add Some $PUSS into your Life With Platypuus</h2>
          <p><strong>Token Name - PlatyPuusToken</strong></p>
          <p><strong>Network    - solana</strong></p>
          <p><strong>Contract Address - </strong>
        <a
          href=" https://solscan.io/token/EZ5RvArAqe2sZPECiVgDo3c1nMWxQY5maBPPVy55Yi24 "
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong> EZ5RvArAqe2sZPECiVgDo3c1nMWxQY5maBPPVy55Yi24 </strong>
        </a>
         </p>
        </div>
        <div className="hero_abstract">
            <Illustration2 className="logo2" />
          {/* <Illustration1 className="logo1" />
          <Illustration3 className="logo3" /> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
